import React from 'react';
import tw, { styled, theme } from 'twin.macro';

const Wrap = styled('div')`
  ${tw`uppercase text-center text-blue font-heading relative`};
  height: 25px;
  margin-bottom: 15px;
  font-size: 118.75%;
  span {
    ${tw`relative`};
    padding: 0 10px;
    background: black;
    z-index: 1;
    display: inline-block;
  }
  &:after {
    ${tw`absolute left-0 right-0`};
    top: 50%;
    height: 1px;
    margin-top: -1px;
    background: ${theme`colors.blue`};
    content: ' ';
  }
`;

const Or = () => (
  <Wrap>
    <span>Or</span>
  </Wrap>
);

export default Or;
