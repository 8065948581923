import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import Loading from 'src/components/Loading';
import Or from 'src/components/Or';
import ConfirmForm from 'src/components/form/ConfirmForm';
import LoginForm from 'src/components/form/LoginForm';
import { Body, Grid } from 'src/components/layout/Wrap';
import Hi from 'src/components/svg/Hi';
import { loggedInSelector } from 'src/state/selectors';
import { Center } from 'src/styles/Alignment';
import { Page } from 'src/styles/Layout';
import { P, PageTitle } from 'src/styles/Typography';
import Query from 'src/utils/Queries';
import emailRegex from 'src/utils/emailRegex';
import { styled } from 'twin.macro';
const CustomMargin = styled.div`
  flex-direction: column;
  flex: 1;
  align-items: center;
  display: flex;
  ${Query.tablet} {
    margin-top: 136px;
  }
`;

const Welcome: FC<{
  loggedIn: boolean;
}> = ({ loggedIn }) => {
  interface Provided {
    email: string;
    name: string;
  }
  const location = useLocation();
  const [homeLoading, setHomeLoading] = useState(true);
  const [provided, setProvided] = useState<Provided>({
    email: '',
    name: ''
  });

  useEffect(() => {
    const params: URLSearchParams = new URLSearchParams(location.search);
    const name = params.get('name') || '';
    const email = params.get('email');
    const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);

    if (capitalizedName && email) {
      if (emailRegex(email)) {
        setProvided({ name: capitalizedName, email });
      }
    } else {
      setProvided({
        email: '',
        name: ''
      });
    }
  }, [location.search]);

  useEffect(() => {
    if (loggedIn) {
      navigate('/app');
    } else {
      setHomeLoading(false);
    }
  }, [loggedIn]);

  if (homeLoading) {
    return null;
  }

  const isProvided = provided.email !== '';

  return (
    <Body>
      <Page>
        <Grid>
          {isProvided ? (
            <Center>
              <CustomMargin>
                <Hi />
                <PageTitle>Hi {provided.name},</PageTitle>
                <P large>
                  Before we get started, is this the email address you&apos;d
                  like your revision notes sent to?
                </P>
                <ConfirmForm
                  name={provided.name}
                  email={provided.email}
                  setHomeLoading={setHomeLoading}
                />
              </CustomMargin>
            </Center>
          ) : (
            <>
              <h1>Sign In</h1>
              <P large>Enter your details to start today&apos;s session.</P>
              <LoginForm setHomeLoading={setHomeLoading} />
              <Or />
              <P large>
                Enter the short url at your login station into your preferred
                browser.
              </P>
            </>
          )}
        </Grid>
      </Page>
    </Body>
  );
};

export default connect(state => ({
  loggedIn: loggedInSelector(state)
}))(Welcome);
