import { Formik, FormikProps } from 'formik';
import { navigate } from 'gatsby';
import React, { FC, useCallback } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Button from 'src/components/Button';
import TextInput from 'src/components/form/TextInput';
import { setUser } from 'src/state/reducers/auth';
import dataLayerPush from 'src/utils/dataLayerPush';
import * as Yup from 'yup';
import { Form } from './styles';
import { emailValid, emptyValid } from './validations';

const validationLogin = Yup.object().shape({
  email: emailValid(),
  name: emptyValid('Please enter your name')
});

interface Login {
  email: string;
  name: string;
}

const LoginForm: FC<{
  dispatch: Dispatch;
  setHomeLoading: Function;
}> = ({ dispatch, setHomeLoading }) => {
  const manageSubmit = useCallback(
    values => {
      const capitalizedName =
        values.name.charAt(0).toUpperCase() + values.name.slice(1);

      dispatch(
        setUser({
          name: capitalizedName,
          email: values.email
        })
      );
      setHomeLoading(true);
      dataLayerPush({
        event: 'trackEvent',
        eventCategory: 'Forms',
        eventAction: 'Login',
        eventLabel: 'Login'
      });
      navigate('/app');
    },
    [dispatch, setHomeLoading]
  );

  return (
    <Formik
      initialValues={{
        name: '',
        email: ''
      }}
      id="loginForm"
      validationSchema={validationLogin}
      onSubmit={manageSubmit}
    >
      {(formikProps: FormikProps<Login>) => (
        <Form noValidate onSubmit={formikProps.handleSubmit} id="loginForm">
          <TextInput id="name" name="name" label="First name" required />
          <TextInput
            required
            id="email"
            name="email"
            label="School email"
            type="email"
          />
          <Button
            type="submit"
            glow="blue"
            disabled={
              formikProps.values.name === '' || formikProps.values.email === ''
            }
          >
            Start session
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default connect()(LoginForm);
