import { useField, useFormikContext } from 'formik';
import React, { FC } from 'react';
import tw, { css, styled } from 'twin.macro';
import Email from '../svg/Email';
import {
  HelpText,
  InputGroup,
  InputWrap,
  Label,
  baseInputStyles
} from './styles';
interface Props {
  error?: boolean;
  hasIcon?: boolean;
}

export const Input = styled('input')<Props>`
  ${baseInputStyles};
  ${tw`
    h-12
    w-full
  `};
  ${({ hasIcon }) =>
    hasIcon &&
    css`
      padding-left: 44px;
    `}
`;

const TextInput: FC<{
  id?: string;
  type?: string;
  label: string;
  name: string;
  required?: boolean;
  placeholder?: string;
  icon?: string;
}> = ({
  id,
  type = 'text',
  name,
  label,
  placeholder,
  required = false,
  icon
}) => {
  const [field, meta] = useField(name);
  const { isSubmitting, submitCount } = useFormikContext();
  const error = meta.error;
  const submitAttempted = submitCount > 0;
  const outputError = error && submitAttempted;

  return (
    <InputGroup>
      <Label htmlFor={id}>{label}</Label>
      <InputWrap hasIcon={!!icon}>
        {icon === 'email' && <Email />}
        <Input
          name={name}
          id={id}
          value={meta.value}
          onChange={field.onChange} // eslint-disable-line react/jsx-handler-names
          onBlur={field.onBlur} // eslint-disable-line react/jsx-handler-names
          type={type}
          disabled={isSubmitting}
          autoComplete="off"
          error={!!outputError}
          placeholder={placeholder}
          required={required}
          hasIcon={!!icon}
          aria-invalid={!!(outputError && required)}
        />
      </InputWrap>
      {outputError && <HelpText error>{error}</HelpText>}
    </InputGroup>
  );
};

export default TextInput;
