import React from 'react';
import SVG from './SVG';
const Email = () => (
  <SVG width={24} height={24} viewBox="0 0 24 24">
    <path
      fill="#F8971D"
      d="M16.75 13.5c0 .966.784 1.75 1.75 1.75.926 0 1.684-.72 1.746-1.63l.004-.12v-1.501c.005-3.64-2.377-6.853-5.86-7.907-3.485-1.054-7.248.3-9.262 3.332-2.014 3.032-1.803 7.025.52 9.828 2.322 2.803 6.207 3.753 9.56 2.337.382-.161.822.018.983.4.161.38-.018.82-.4.982-3.963 1.673-8.554.55-11.299-2.762-2.744-3.312-2.994-8.032-.614-11.615 2.38-3.584 6.828-5.184 10.946-3.938 4.042 1.223 6.829 4.906 6.924 9.11l.002.234v1.5c0 1.795-1.455 3.25-3.25 3.25-1.234 0-2.308-.688-2.858-1.701-.87 1.04-2.179 1.701-3.642 1.701-2.623 0-4.75-2.127-4.75-4.75S9.377 7.25 12 7.25s4.75 2.127 4.75 4.75v-.001V13.5zM12 8.75c-1.795 0-3.25 1.455-3.25 3.25s1.455 3.25 3.25 3.25 3.25-1.455 3.25-3.25S13.795 8.75 12 8.75z"
    />
  </SVG>
);

export default Email;
