import React from 'react';
import SVG from './SVG';
const Hi = () => (
  <SVG viewBox="0 0 96 96" width={96} height={96} title="Hi">
    <defs>
      <filter
        id="la4e8k22na"
        width="180%"
        height="202.9%"
        x="-40%"
        y="-51.4%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="12"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0.568627451 0 0 0 0 0.854901961 0 0 0 1 0"
        />
        <feOffset in="SourceAlpha" result="shadowOffsetOuter2" />
        <feGaussianBlur
          in="shadowOffsetOuter2"
          result="shadowBlurOuter2"
          stdDeviation="6"
        />
        <feColorMatrix
          in="shadowBlurOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 0 0 0 0 0 0.568627451 0 0 0 0 0.854901961 0 0 0 1 0"
        />
        <feOffset in="SourceAlpha" result="shadowOffsetOuter3" />
        <feGaussianBlur
          in="shadowOffsetOuter3"
          result="shadowBlurOuter3"
          stdDeviation="3"
        />
        <feColorMatrix
          in="shadowBlurOuter3"
          result="shadowMatrixOuter3"
          values="0 0 0 0 0 0 0 0 0 0.568627451 0 0 0 0 0.854901961 0 0 0 1 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
          <feMergeNode in="shadowMatrixOuter3" />
        </feMerge>
      </filter>
      <path
        id="6tcdqk2bbb"
        d="M83.27 13c5.374 0 9.73 4.33 9.73 9.672v41.107c0 5.34-4.355 9.669-9.727 9.669H20.297c-1.183 0-2.325.429-3.214 1.206l-8.028 7.023C7.826 82.584 6.728 83 5.817 83 4.062 83 2.995 81.458 3 78.903l.028-56.234c0-5.34 4.355-9.669 9.726-9.669zm0 3H12.753c-3.709 0-6.726 2.992-6.726 6.669L6 78.905c0 .474.054.81.111 1.024.238-.086.6-.262 1.072-.598l7.926-6.935c1.436-1.256 3.28-1.948 5.19-1.948h62.975c3.71 0 6.726-2.991 6.726-6.669V22.672C90 18.993 86.98 16 83.27 16zm-46.112 9.576c.878 0 1.756.596 1.756 1.745v9.235c.786-.828 2.404-1.378 4.021-1.378h.37c4.114 0 7.441 3.308 7.441 7.397v13.553c0 1.148-.878 1.746-1.756 1.746s-1.756-.598-1.756-1.745V42.575c0-2.665-2.08-3.906-4.114-3.906-2.126 0-4.206 1.288-4.206 3.677v13.783c0 1.148-.878 1.745-1.756 1.745s-1.756-.598-1.756-1.745V27.322c0-1.15.878-1.746 1.756-1.746zm22.045 9.602c.878 0 1.756.597 1.756 1.745V56.13c0 1.148-.878 1.745-1.756 1.745s-1.756-.598-1.756-1.745V36.922c0-1.148.878-1.745 1.756-1.745zm0-9.602c.878 0 1.756.596 1.756 1.745v1.011c0 1.148-.878 1.746-1.756 1.746s-1.756-.598-1.756-1.745v-1.011c0-1.15.878-1.746 1.756-1.746z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <use fill="#000" filter="url(#la4e8k22na)" xlinkHref="#6tcdqk2bbb" />
      <use fill="#FFF" xlinkHref="#6tcdqk2bbb" />
    </g>
  </SVG>
);

export default Hi;
