import { Formik, FormikProps } from 'formik';
import { navigate } from 'gatsby';
import React, { FC, useCallback, useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import CheckIconButton from 'src/components/CheckIconButton';
import LinkButton from 'src/components/LinkButton';
// import * as M from 'src/components/Modal';
import Modal from 'src/components/Modal';
import TextInput from 'src/components/form/TextInput';
import { setUser } from 'src/state/reducers/auth';
import dataLayerPush from 'src/utils/dataLayerPush';
import * as Yup from 'yup';
import { Form, FormBottom } from './styles';
import { emailValid } from './validations';

const validationLogin = Yup.object().shape({
  email: emailValid()
});

interface Confirm {
  email: string;
}

const ConfirmForm: FC<{
  dispatch: Dispatch;
  email: string;
  name: string;
  setHomeLoading: Function;
}> = ({ dispatch, email, name, setHomeLoading }) => {
  const [show, setShow] = useState(false);
  const goToLogin = useCallback(
    e => {
      e.preventDefault();
      navigate('/');
      dispatch(
        setUser({
          email: '',
          name: ''
        })
      );
    },
    [dispatch]
  );

  const showModal = useCallback(() => {
    setShow(true);
  }, [setShow]);

  const hideModal = useCallback(() => {
    setShow(false);
  }, [setShow]);

  const manageSubmit = useCallback(
    values => {
      setHomeLoading(true);
      dataLayerPush({
        event: 'trackEvent',
        eventCategory: 'Forms',
        eventAction: 'Login',
        eventLabel: 'QR Login'
      });
      navigate('/app');
      dispatch(
        setUser({
          name,
          email: values.email
        })
      );
    },
    [dispatch, name, setHomeLoading]
  );

  return (
    <>
      <Formik
        initialValues={{
          email
        }}
        id="confirmForm"
        validationSchema={validationLogin}
        onSubmit={manageSubmit}
      >
        {(formikProps: FormikProps<Confirm>) => (
          <Form
            noValidate
            hasBottom
            maxWidth
            onSubmit={formikProps.handleSubmit}
            id="confirmLoginForm"
          >
            <TextInput
              required
              id="email"
              name="email"
              label=""
              type="email"
              icon="email"
            />
            <p>
              <LinkButton small type="button" onClick={showModal}>
                See terms and conditions
              </LinkButton>
            </p>

            <FormBottom>
              <ScrollAnimation offset={0} delay={1000} animateIn="fadeInUp">
                <p>
                  <CheckIconButton
                    type="submit"
                    disabled={formikProps.values.email === ''}
                  />
                </p>
                <p>
                  <LinkButton type="button" onClick={goToLogin}>
                    I am not {name}
                  </LinkButton>
                </p>
              </ScrollAnimation>
            </FormBottom>
          </Form>
        )}
      </Formik>
      <Modal show={show} closeDisabled={false} closeCallback={hideModal}>
        <p>Terms TBA</p>
      </Modal>
    </>
  );
};

export default connect()(ConfirmForm);
